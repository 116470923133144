import {
  useParams,
  useSearchParams
} from "react-router-dom";
import {
  useState,
  useEffect
} from 'react';
import "./App.css";
import { setWatchPartyCurrentlyPlaying, watchPartyAlreadyExists } from './watchPartyCurrentlyPlaying';
import { WaitingToRedirect } from './WaitingToRedirect';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { usePromiseEffect } from "./usePromiseEffect";

const defaultVideoUrl = "https://youtu.be/Pcl89zHT83Q";

function NewWatchParty() {
  const { newPasscode } = useParams();
  const [ searchParams ] = useSearchParams();
  const searchString = searchParams.toString();
  const queryString = searchString ? `?${searchString}` : '';
  const [ watchPartyRedirect, setWatchPartyRedirect ] = useState({ ready: false, destination: undefined, error: undefined });
  const [ user ] = useAuthState(auth);
  const watchPartyReadyData = usePromiseEffect(async () => {
    return watchPartyAlreadyExists(newPasscode)
      .then((exists) => {
        if (exists) return Promise.reject(new Error(`Watch party ${newPasscode} already exists`));
        setWatchPartyCurrentlyPlaying(defaultVideoUrl, user?.uid || 'anonymous', newPasscode);
      });
  }, []);
  useEffect(() => {
    const ready = watchPartyReadyData.status === 'fulfilled' || watchPartyReadyData.status === 'rejected';
    if (!ready) return;
    if (watchPartyReadyData.status === 'rejected') {
      setWatchPartyRedirect({
        ready,
        destination: `/${queryString}`,
        error: watchPartyReadyData.error
      });
    } else {
      setWatchPartyRedirect({
        ready,
        destination: `/${newPasscode}${queryString}`,
        error: undefined
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ watchPartyReadyData ])
  return (
    <div>
      <header className="App-header">
        <WaitingToRedirect state={watchPartyRedirect}/>
      </header>
    </div>
  );
}

export default NewWatchParty;
