function DemoWorldModal(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>Demo World</h2>
    <br />
    <div className="modalScrollableContent">
      <h3>Where can you see VRShare.app in action?</h3>
      <p>There's a dedicated cinema world <a href="https://vrchat.com/home/world/wrld_94fb9a0f-7074-4c02-91c4-010f18b7b85e" target="_blank" rel="noopener noreferrer">here</a> - or, in VRChat, search for "VRShare"</p>
      <p>There's also a world with other features called Social Study in Space <a href="https://vrchat.com/home/world/wrld_d80a9c8a-ce9e-462c-af9d-100607273c46" target="_blank" rel="noopener noreferrer">here</a> - or search for "Social Study in Space"</p>
      <br />
      <h3>Does it work on Quest?</h3>
      <p>Of course! The video player is pointed to https://vrshare.app/watch by default and there are buttons for the demo and the VRShare.app video and stream links. But now that Quest users can enter URLs, it is recommended to hard code the passcode URL once you've got one, if you intend to watch with other users. You can even use copy and paste functionality if you select your video on this website using the browser on your Quest device before jumping into VRChat 👍</p>
    </div>
    <div className="modalFooter">
      <h3>Need more help? <a href="https://discord.gg/waA36sY3vU" target="_blank" rel="noreferrer">Join our Discord</a> and ask me there!</h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default DemoWorldModal;