Object.defineProperty(exports, "__esModule", {
  value: true
});
// http://stackoverflow.com/a/6969486/692528
var escapeRegExp = function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); //eslint-disable-line no-useless-escape
};

var create = function create(chars) {
  var charCodes = chars.map(function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });

  var charToCode = {};
  var codeToChar = {};
  chars.forEach(function (c, i) {
    charToCode[c] = charCodes[i];
    codeToChar[charCodes[i]] = c;
  });

  var charsRegex = new RegExp('[' + escapeRegExp(chars.join('')) + ']', 'g');
  var charCodesRegex = new RegExp(charCodes.join('|'), 'g');

  var encode = function encode(str) {
    return str.replace(charsRegex, function (match) {
      return charToCode[match];
    });
  };
  var decode = function decode(str) {
    return str.replace(charCodesRegex, function (match) {
      return codeToChar[match];
    });
  };

  return { encode: encode, decode: decode };
};

// http://stackoverflow.com/a/19148116/692528

var _create = create('.$[]#/%'.split(''));

var encode = _create.encode;
var decode = _create.decode;

// Without `/`.

var _create2 = create('.$[]#%'.split(''));

var encodeComponents = _create2.encode;
var decodeComponents = _create2.decode;
exports.create = create;
exports.encode = encode;
exports.decode = decode;
exports.encodeComponents = encodeComponents;
exports.decodeComponents = decodeComponents;
exports.default = {
  create: create,
  encode: encode,
  decode: decode,
  encodeComponents: encodeComponents,
  decodeComponents: decodeComponents
};
