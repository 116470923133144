import validPasscodeTest from "./passCodeTests";
// Courtesy of https://github.com/dariusk/corpora/blob/master/data/words/nouns.json
import adverbsJson from './adverbs.json';
import adjectivesJson from './adjectives.json';
import nounsJson from './nouns.json';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { usePromiseEffect } from "./usePromiseEffect";
import ErrorMessage from "./ErrorMessage";
import { watchPartyAlreadyExists } from "./watchPartyCurrentlyPlaying";

const loadingPasscode = 'Loading...';

function getNewPasscode() {
  const adverbs = adverbsJson.adverbs;
  const adjectives = adjectivesJson.adjectives;
  const nouns = nounsJson.nouns;
  const randomAdverb = adverbs[Math.floor(Math.random() * adverbs.length)];
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${randomAdverb.toLowerCase()}-${randomAdjective.toLowerCase()}-${randomNoun.toLowerCase()}`;
}

function getNewValidPasscode() { 
  let newPasscode;
  let valid = false;
  while (!valid) {
    newPasscode = getNewPasscode();
    valid = validPasscodeTest(newPasscode).passed;
  }
  return newPasscode;
}

function StartNewButton(props) {
  const { newPasscode } = props;
  const navigate = useNavigate ();
  const [ searchParams ] = useSearchParams();
  function handleClick() {
    searchParams.delete('oldPasscode');
    const searchString = searchParams.toString();
    const queryString = searchString ? `?${searchString}` : '';
    if (newPasscode === loadingPasscode) {
      // get() sometimes called too early, and newPasscode state never updates
      // or sometimes rate limited and the user is too eager to click
      const passcodeFinalAttempt = getNewValidPasscode();
      // Navigate without checking for existence and handle (low probability) errors there
      navigate(`/new/${passcodeFinalAttempt}${queryString}`);
    } else {
      navigate(`/new/${newPasscode}${queryString}`);
    }
  }
  return <button className="button" onClick={handleClick}>Start a new watch party</button>
}

function PasscodeDisplay(props) {
  const { newPasscode, shuffler } = props;
  return <>
    <input type="text" value={newPasscode} disabled></input>
    <img id="shufflebutton" src="shuffle.svg" alt="shuffle" onClick={shuffler} />
  </>
}

function GeneratePasscode(props) {
  const [ newPasscode, setNewPasscode ] = useState(loadingPasscode);
  const [ manualPasscodeRefreshes, setManualPasscodeRefreshes ] = useState(0);
  // Inelegant to hardcode the number of retries here, but it at least clearly avoids infinite regression
  const newPasscodeData = usePromiseEffect(async () => {
    const firstTry = getNewValidPasscode();
    const secondTry = getNewValidPasscode();
    const thirdTry = getNewValidPasscode();
    const fourthTry = getNewValidPasscode();
    const fifthTry = getNewValidPasscode();
    const passcodeAttempts = [
      async () => { return watchPartyAlreadyExists(firstTry).then((exists) => exists ? undefined : firstTry); },
      async () => { return watchPartyAlreadyExists(secondTry).then((exists) => exists ? undefined : secondTry); },
      async () => { return watchPartyAlreadyExists(thirdTry).then((exists) => exists ? undefined : thirdTry); },
      async () => { return watchPartyAlreadyExists(fourthTry).then((exists) => exists ? undefined : fourthTry); },
      async () => { return watchPartyAlreadyExists(fifthTry).then((exists) => exists ? undefined : fifthTry); }
    ];
    const newPasscode = new Promise((resolve, reject) => {
      passcodeAttempts[0]()
        .then((result) => result || passcodeAttempts[1]())
        .then((result) => result || passcodeAttempts[2]())
        .then((result) => result || passcodeAttempts[3]())
        .then((result) => result || passcodeAttempts[4]())
        .then((result) => result ? resolve(result) : reject(new Error('Too many retries')));
    });
    return newPasscode;
  }, [ manualPasscodeRefreshes ]);
  useEffect(() => {
    setNewPasscode(newPasscodeData.status === 'rejected' ? <ErrorMessage error={newPasscodeData.error} /> : (newPasscodeData.status === 'fulfilled' ? newPasscodeData.value : 'Loading...'))
  }, [ newPasscodeData ])
  function shuffle() {
    setManualPasscodeRefreshes(manualPasscodeRefreshes + 1);
  }
  return <>
    <div className='verticallycentred'>
    <PasscodeDisplay newPasscode={newPasscode} shuffler={shuffle} />
    </div>
    <StartNewButton newPasscode={newPasscode} />
  </>
}

export default GeneratePasscode;