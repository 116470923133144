function FallbackUI(props) {
  const { errorMessage } = props;
  return (
    <header className="App-header">
      <h1>Oh no, there was an error 😢</h1>
      <p>{errorMessage || "I'm sorry, there's no further information available here"}</p>
      <p>Find further details in the console.</p>
    </header>
  );
}

export default FallbackUI;
