function FAQModal(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>FAQ</h2>
    <br />
    <div className="modalScrollableContent">
      <h3>Why do I need a passcode?</h3>
      <p>We can't tell what VRChat world instance you're in, so any player pointed to https://VRShare.app/watch gets the same default video to start with. Once you associate yourself to a watch party using a browser from a device on the same local network, you'll be directed to whatever video is playing in-world.</p>
      <p>But if the video player is already pointed to a specific watch party (e.g. https://VRShare.app/watch/passcode-goes-here), then everyone in-world is automatically already in the right watch-party, and will go straight to the right watch party web page if they navigate to https://VRShare.app - that's a much nicer way of doing things, especially now that Quest users can enter URLs, too 👍</p>
      <br />
      <h3>Why does my browser have to be on a device from the same network as my VRChat client?</h3>
      <p>Unless you've navigated straight to a 'manage' URL of the form https://VRShare.app/manage/passcode-goes-here and your in-world player is hardcoded to a 'watch' URL of the form https://VRShare.app/watch/passcode-goes-here, we use your internet-visible IP to identify you and figure out which watch party you should be in from both the browser and the video player. Without an API from VRChat to see what world instance you're in, we have no other option 😟</p>
      <br />
      <h3>Woah... You don't store my IP address do you?</h3>
      <p>Hell, no! We hash it, complete with lashings of salt and pepper. We can identify you when you return, but your raw IP addres is never saved, and reverse engineering the hash is impractical to the point of impossibility 👍</p>
      <br />
      <h3>I've put in the right passcode, but the in-world player is just playing the default video</h3>
      <p>Assuming you've already done a rewind and resync (or just a resync if everyone else is watching already), then is the browser you used to enter the passcode on the same local network as the VRChat client? If you're using a mobile browser and it's dropped off Wi-Fi to mobile data, those IPs won't match... Check that first, and if you need more help, please do ask on Discord</p>
      <br />
      <h3>I'm seeing a different video to everyone else, help!</h3>
      <p>Have you entered the same passcode on this website as everyone else? If so, try resyncing the player (not restarting, that'd affect everyone 😆). Or, try rejoining the world instance, as that's gauranteed to resync you and only you.</p>
      <br />
      <h3>Will this work with streams?</h3>
      <p>Perhaps! If you've got a .m3u8 URL, I've found that in order for it to play for Quest users, the URL in the video player should <i>also</i> end in .m3u8 - to facilitate that you can point the player to https://VRShare.app/watch.m3u8 (or https://VRShare.app/watch/passcode-goes-here.m3u8 if you're hardcoding to a specific watch party) 😊 - bear in mind that some Quest video players show streams upside down 😬 - see below</p>
      <br />
      <h3>My video is playing upside down!</h3>
      <p>I have discovered some video players on Quest play upside down in stream mode. If it's not a stream, make sure to exit the video player stream mode, if applicable, and/or point the video player to https://vrshare.app/watch or https://vrshare.app/watch/passcode-goes-here rather than .../watch.m3u8 or .../watch/passcode-goes-here.m3u8</p>
      <p>I'll reach out to the affected video player developers on this, soon. The default AVPro player in VRChat doesn't exhibit this behaviour, indicating it's an erroneous 'fix' developers used for prior versions of AVPro.</p>
      <br />
      <h3>Why doesn't this particular YouTube video work for Quest users?</h3>
      <p>The URL of the underlying YouTube video is resolved for Quest users using the third party 'Jinnai' service (because VRChat does not provide the same youtube-dl functionality to Quest users that it does for PC users - this is why direct YouTube links don't work when entered into VRChat video players on Quest). The underlying URL that servce retrieves includes parameters e.g. cookie-stored values or the IP address of the requesting party. Often these parameters are not checked against subsequent requests, allowing users on different IPs and without the same browser cookies to view the video anyway. But sometimes they are checked, and Google refuses to serve the URL as it detects the request is not from the original requesting party, the Jinnai server. This is YouTube functionality that cannot be mitigated, but you can test to see if this applies in your case by following the below instructions:</p>
      <br />
      <h3>How can I tell if a specific URL will load properly for Quest users?</h3>
      <p>Once the video in question has been added to the watch party, you can follow the Quest link displayed to the right of the video details. This will attempt to load the underlying URL served to Quest users in a new browser tab. If it loads, it should work for all Quest users. If not, it won't - see above.</p>
      <br />
      <h3>Can I deliberately watch something other than everyone else in the world?</h3>
      <p>If the player is pointed to https://VRShare.app/watch and not any specific watch party, then, technically, yes. But expect trouble. If the video player is configured to sync for all world visitors, this is likely to cause issues e.g. not playing fully, or being interfered with by other people using the video player controls.</p>
      <br />
      <h3>I'm a creator - Can I set my own VRChat world video player to VRSHare.app by default? Can or should I use a specific watch party code?</h3>
      <p>I'd love it if you pointed your video player to VRShare.app by default! It makes a nice flow to find a video of choice, share it to VRShare and then fire up VRChat into your favourite world to watch straight away.</p>
      <p>However, I would advise against hardcoding a specific watch party passcode - they're currently randomised codes, and they expire if not used after a certain amount of time. Also, the service can't distinguish between world <i>instances</i> so people in the same watch party across different instances trying to manage the currently playing video for own viewing group would intefere with each other's viewing e.g. changing the video for those in the other instance, too.</p>
      <p>But customisable, world-specific watch parties, with default playlists, are definitely a planned feature! Join the Discord to keep up to date!</p>
    </div>
    <div className="modalFooter">
      <h3>Need more help? <a href="https://discord.gg/waA36sY3vU" target="_blank" rel="noreferrer">Join our Discord</a> and ask me there!</h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default FAQModal;