function ReportAbuse(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>Rebort Abuse</h2>
    <div className="modalScrollableContent">
      <br />
      <h3>Report illegal or abusive content</h3>
      <p>Simply send me a message in <a href="https://discord.gg/waA36sY3vU" target="_blank" rel="noreferrer">Discord</a>, or send an email via the link below.</p>
      <p>If it is in relation to a specific watch party or video, copy and paste the watchparty URL and/or video URL into your message, and we'll check it out.</p>
      <p>And thanks for helping to keep the service safe 😊</p>
    </div>
    <div className="modalFooter">
      <h3>Click to email</h3>
      <h3><a href="mailto:daninvirtualreality@gmail.com" target="_blank" rel="noreferrer">Email DanInVirtualReality</a></h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default ReportAbuse;