// import { fetch } from 'fetch-opengraph';
// import { useEffect, useState } from 'react';
// import { usePromiseEffect } from './usePromiseEffect';

function VideoCard(props) {
  const defaultThumbnail = 'play.svg';
  const defaultSource = "Waiting Screen"
  const defaultTitle = "Default Video"
  const { title, source, url, thumbnail } = props;
  const parsedUrl = url ? new URL(url) : undefined;
  const titleFromUrl = url
    ? parsedUrl.pathname.slice(parsedUrl.pathname.lastIndexOf('/') + 1) + parsedUrl.search || 'Unknown Title'
    : defaultTitle;
  const titleToUse = title || titleFromUrl;
  const sourceFromUrl = url
    ? parsedUrl.hostname || 'Unknown Source'
    : defaultSource;
  const sourceToUse = source || sourceFromUrl;

  const thumbnailToUse = thumbnail || defaultThumbnail;

  // TODO reimplement if I can find a CORS friendly og-info scraper, or as a server-side addition
  /*
  const [ thumbnailToUse, setThumbnailToUse ] = useState(defaultThumbnail);
  const ogData = usePromiseEffect(async () => {
    if (thumbnail) {
      return { image: thumbnail };
    }
    if (url) {
      return fetch(parsedUrl.toString());
    }
    return {};
  }, [ url ])
  useEffect(() => {
    console.log('OG data', ogData);
    setThumbnailToUse(ogData.status === 'fulfilled' ? (ogData.value?.image || defaultThumbnail) : defaultThumbnail);
  }, [ ogData ])
  */
 
  return <div className="videoCardContainer">
    <div className="videoCardThumbnail">
      <img src={thumbnailToUse} alt="Video Thumbnail" />
    </div>
    <div className="videoCardDetails">
      <p className="videoCardSource">{sourceToUse}</p>
      <p className="videoCardTitle">{titleToUse}</p>
    </div>
    <div className="videoCardLink">
      <p>PC:<a href={url || '#'} target="_blank" rel="noopener noreferrer"><img className="videoCardLinkIcon" src="go.svg" alt="Visit PC video URL" /></a></p>
      <p>Quest:<a href="https://vrshare.app/watch?platform=quest" target="_blank" rel="noopener noreferrer"><img className="videoCardLinkIcon" src="go.svg" alt="Visit Quest video URL" /></a></p>
    </div>
  </div>;
}

export default VideoCard
