function PlannedFeatures(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>Planned Features</h2>
    <div className="modalScrollableContent">
      <br />
      <h3>Playlists</h3>
      <p>at the very least a play history so you can easily find what you were watching again</p>
      <br />
      <h3>Video hosting</h3>
      <p>A costly one and, thanks to DMCA and EU Copyright Directive Article 17, a fairly risky one... 🤔 - If it happens, <a href="https://www.patreon.com/bePatron?u=63139367" target="_blank" rel="noreferrer">Patrons</a> will get it first!</p>
      <br />
      <h3>Customisable, world-specific watch parties</h3>
      <p>With adjustable themes, playlists and more. For sure!</p>
    </div>
    <div className="modalFooter">
      <h3>Do you have suggestion?</h3>
      <h3><a href="https://discord.gg/waA36sY3vU" target="_blank" rel="noreferrer">Join our Discord</a> and let me know!</h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default PlannedFeatures;