import bookmarklet from './vrshare-bookmarklet-src';
import { version } from './vrshare-bookmarklet-src';

const bookmarkletCode = `javascript:${encodeURIComponent(`(${bookmarklet.toString().trim()})();`)}`;

function disallowBookmarkletClick(event) {
  alert("No, don't click it here - drag it to your bookmarks bar and use it on other web pages 😊");
  event.preventDefault();
  return false;
}

function BookmarkletModal(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>Get the Bookmarklet - v{version}</h2>
    <h3>Easy video sharing for VRChat PC Users:</h3>
    <br />
    <div className="modalScrollableContent">
      <h3>On PC, drag this link to your bookmarks bar:</h3>
      <ul><li><a href={bookmarkletCode} onClick={disallowBookmarkletClick}>Send to VRShare.app</a></li></ul>
      <p>Don't click it here, that's not how it works 😊</p>
      <p>Once you've dragged it to your bookmarks bar, click it from any video web page!</p>
      <br />
      <h3>What's a bookmarklet?</h3>
      <p>Javascript can be embedded in a browser bookmark, to execute extra functionality on a webpage when you click it. They sit alongside your regular browser bookmarks, so typically you'd want to add them to your bookmarks bar for easy access.</p>
      <p>I've made one here to facilitate easier sharing of whatever webpage you're currently viewing (or, alternatively, one of the embedded videos, links to video files or frames, if any are found) into the VRShare app.</p>
      <br />
      <h3>Why not a Chrome extension?</h3>
      <p>There is one! But since the Chrome Web Store won't allow extensions that extract sources of videos from e.g. YouTube, the installation is more manual.</p>
      <p>See the instructions for 'Get the Extension' to get that instead.</p>
      <br />
      <h3>Does a bookmarklet have to be PC only?</h3>
      <p>It's VERY fiddly for users on mobiles to use bookmarklets. It's better to install the web app by navigating here on a mobile browser - a button shows in the menu if it's not already installed.</p>
      <p>Once installed, VRShare then shows up in the list of possible destinations for native sharing - e.g. try the share button beneath the playing video in the YouTube app.</p>
      <br />
      <h3>How do you install the bookmarklet?</h3>
      <p>Easy: Just drag the link to the bookmarks bar of your browser!</p>
      <br />
      <h3>How do you use it?</h3>
      <p>When you're on a web page with a video you want to watch in VRChat, simply click the bookmarklet and a popup will display, asking you which link you want to share - the page link, or any embedded media, video file links or frames found.</p>
      <br />
      <h3>Which URL should I choose?</h3>
      <p>If you're on a well-known video platform, like YouTube or Vimeo, viewing the video you want to share, just use the page URL.</p>
      <p>If you're on a third-party page with an embedded YouTube or Vimeo video, choose the appropriate YouTube or Vimeo URL from the frames URLs listed.</p>
      <p>If you're on a page from some other source with a video player on the page, use the relevant link from embedded media player URLs (it's not always possible to do this as some video hosts transfer 'blobs' of data rather than links to files)</p>
      <p>If you're on a page with links to the video files, choose the relevant media link URL - not all formats are supported (testing continues as to what kinds of files VRChat clients can play - .mp4 and .m3u8 are known-good 👍)</p>
      <br />
      <h3>Why are there so many frame URLs?</h3>
      <p>Short answer: advertising.</p>
      <p>Frames are typically used to load advertising and user tracking content - the bookmarklet doesn't yet employ any logic to ignore irrelevant frames</p>
      <p>(It's impractical to filter frame URLs by all the possible types that the underlying youtube-dl resolver might be able to handle, so we don't do this in the bookmarklet.)</p>
      <br />
      <h3>Can I share a link on the page instead?</h3>
      <p>Only links to known video file types currently show in the list i.e. no YouTube, Vimeo page links. For those, follow the link in the browser, then use the bookmarklet from there to share the page URL.</p>
      <p>(It's impractical to filter the very many links that show on web pages by all the possible types that the underlying youtube-dl resolver might be able to handle, so we don't do this in the bookmarklet.)</p>
      <p>If right clicking a link in order to share it is the functionality you're looking for, you'll need to install the browser extension instead - See 'Get the Extension'.</p>
      <br />
      <h3>How do I update the bookmarklet?</h3>
      <p>I coded the bookmarklet to report it's version code, so the app can alert you if a new one is available. It's easy to upgrade the bookmarklet if yours is out of date:</p>
      <p>Delete the current bookmarklet from your bookmarks bar and then drag the below link (it's always the latest) to your bookmarks bar to replace it 👍</p>
      <br />
      <h3>Why doesn't the bookmarklet have a nice logo like my other bookmarks?</h3>
      <p>Sadly, it's just not possible to give javascript bookmarklets an icon in most browsers 🤷‍♂️</p>
    </div>
    <div className="modalFooter">
      <h3>On your PC, drag this link to your bookmarks bar:</h3>
      <h3><a href={bookmarkletCode} onClick={disallowBookmarkletClick}>Send to VRShare.app</a></h3>
      <h3>Use it on any video web page!</h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default BookmarkletModal;