import { logEvent } from 'firebase/analytics';
import React from 'react';
import FallbackUI from './FallbackUI';
import { analytics } from './firebase';

// see https://reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: undefined
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    logEvent(analytics , "exception", {
      description: `Error boundary caught: ${error.message}; ${errorInfo}`,
      fatal: true
    });
  }

  render() {
    if (this.state.hasError) {
      return <FallbackUI errorMessage={this.state.errorMessage} />;
    }
    return this.props.children; 
  }
}

export default ErrorBoundary;