import { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  Link
} from "react-router-dom";
import Home from './Home';
import './App.css';
import './SignInOrSignOut';
import SignInOrSignOut from "./SignInOrSignOut";
import validPasscodeTest from './passCodeTests';
import InvalidPasscode from './InvalidPasscode';
import NewWatchParty from "./NewWatchParty";
import LoadingWatchParty from './LoadingWatchParty';
import ErrorBoundary from "./ErrorCatcher";
import CacheBuster from "./CacheBuster";
import { pushRotate as Menu } from 'react-burger-menu';
import Modal from 'react-modal';
import packageInfo from '../package.json';
import DemoWorldModal from './DemoWorldModal';
import BookmarkletModal from './BookmarkletModal';
import ExtensionModal from './ExtensionModal';
import FAQModal from './FAQModal';
import PlannedFeatures from './PlannedFeatures';
import ReportAbuse from './ReportAbuse';
import QueryCleanser from './QueryCleanser';
import { LinkWithQuery } from './LinkWithQuery';
import PWAInstallPrompt from './PWAInstallPrompt';

const { version } = packageInfo;

function WatchPartyOnlyIfValidFormatPasscode() {
  const { passcode } = useParams();
  const validPasscode = validPasscodeTest(passcode);
  if (!validPasscode) {
    return (
      <Navigate replace to={`/invalid/${passcode}?reason=${validPasscode.failedReason}`} />
    );
  }
  return <LoadingWatchParty passcode={passcode} />
}

function ReportAndPrintVersion() {
  console.log(`Loading VRShare.app version ${version}`);
  return <>{version}</>
}

function Loading() {
  return (
    <Router basename="/manage">
      <div className="App" id="app">
        <div id="videoWrapper">
          <video playsInline autoPlay muted loop id="backgroundVideo" poster="background.png">
            <source src="background.webm" type="video/webm" />
            <img id="backgroundVideo" src="background.png" alt="" />
          </video>
          <header className="App-header">
            <h1>checking for latest version</h1>
            <p><ReportAndPrintVersion /></p>
          </header>
        </div>
      </div>
    </Router>
  );
}

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  
  const [ modalStates, setModalStates ] = useState({
    demoWorldModalIsOpen: false,
    bookmarkletModalIsOpen: false,
    extensionModalIsOpen: false,
    plannedFeaturesModalIsOpen: false,
    faqModalIsOpen: false,
    reportAbuseModalIsOpen: false
  });
  const [ menuState, setMenuState ] = useState({ menuOpen: false });
  function closeModals() {
    setMenuState({ menuOpen: false });
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: false
    });
  }
  function openDemoWorld() {
    setModalStates({
      demoWorldModalIsOpen: true,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: false
    });
  }
  function openBookmarklet() {
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: true,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: false
    });
  }
  function openExtension() {
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: true,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: false
    });
  }
  function openFaq() {
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: true,
      reportAbuseModalIsOpen: false
    });
  }
  function openPlannedFeatures() {
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: true,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: false
    });
  }
  function openReportAbuse() {
    setModalStates({
      demoWorldModalIsOpen: false,
      bookmarkletModalIsOpen: false,
      extensionModalIsOpen: false,
      plannedFeaturesModalIsOpen: false,
      faqModalIsOpen: false,
      reportAbuseModalIsOpen: true
    });
  }
  function blurOrNot() {
    return Object.values(modalStates).includes(true);
  }
  function mountAppElementForModal(_event) {
    Modal.setAppElement('#app');
  }
  function closeAndContinue(event) {
    closeModals();
    return true;
  }
  function handleMenuStateChange(state) {
    setMenuState({ menuOpen: state.isOpen });
  }
  return (
    <ErrorBoundary>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={true}
        loadingComponent={<Loading />}
      >
        <Router basename="/manage">
          <QueryCleanser />
          <div className={`App ${blurOrNot() ? 'blur' : ''}`} id="app" onLoad={mountAppElementForModal}>
            <div id="videoWrapper">
              <video playsInline autoPlay muted loop id="backgroundVideo" poster="background.png">
                <source src="background.webm" type="video/webm" />
                <img id="backgroundVideo" src="background.png" alt="" />
              </video>
              <div className="header" id="outerContainer">
                <nav>
                  <Menu pageWrapId="pageWrap" outerContainerId="outerContainer" isOpen={menuState.menuOpen} onStateChange={(state) => handleMenuStateChange(state)}>
                    <PWAInstallPrompt />
                    <SignInOrSignOut />
                    <LinkWithQuery id="home" className="menu-item" to="/" onClick={closeAndContinue}>Home</LinkWithQuery>
                    <Link id="demo-watch-party" className="menu-item" to="/demo" onClick={closeAndContinue}>Demo Watch Party</Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="demo-world" className="menu-item" onClick={openDemoWorld}>Demo World</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="bookmarklet" className="menu-item" onClick={openBookmarklet}>Get the Bookmarklet</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="extension" className="menu-item" onClick={openExtension}>Get the Extension</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="faq" className="menu-item" onClick={openFaq}>FAQ</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="plannedFeatures" className="menu-item" onClick={openPlannedFeatures}>Planned Features</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="reportAbuse" className="menu-item" onClick={openReportAbuse}>Report Abuse</a>
                    <a id="discord" className="menu-item" href="https://discord.gg/waA36sY3vU" target="_blank" rel="noreferrer" onClick={closeAndContinue}>Join us on Discord</a>
                    <br />
                    <a href="https://www.patreon.com/bePatron?u=63139367" data-patreon-widget-type="become-patron-button" target="_blank" rel="noreferrer" onClick={closeAndContinue}>Become a Patron!</a>
                    <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
                    <br />
                  </Menu>
                  <a href="/"><img className="navIcon" src='refresh.svg' alt="Refresh" /></a>
                  <Modal
                    isOpen={modalStates.demoWorldModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="Demo World"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <DemoWorldModal closeCallback={closeModals} />
                  </Modal>
                  <Modal
                    isOpen={modalStates.bookmarkletModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="Bookmarklet for PC"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <BookmarkletModal closeCallback={closeModals} />
                  </Modal>
                  <Modal
                    isOpen={modalStates.extensionModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="Extension for Chrome (Desktop)"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <ExtensionModal closeCallback={closeModals} />
                  </Modal>
                  <Modal
                    isOpen={modalStates.faqModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="FAQ"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <FAQModal closeCallback={closeModals} />
                  </Modal>
                  <Modal
                    isOpen={modalStates.plannedFeaturesModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="Planned Features"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <PlannedFeatures closeCallback={closeModals} />
                  </Modal>
                  <Modal
                    isOpen={modalStates.reportAbuseModalIsOpen}
                    onRequestClose={closeModals}
                    contentLabel="Report Abuse"
                    className="modal"
                    overlayClassName="modalOverlay"
                  >
                    <ReportAbuse closeCallback={closeModals} />
                  </Modal>
                </nav>
                <main id="pageWrap">
                  <Routes>
                    <Route path="/" element={<Home version={version} />} />
                    <Route path="/invalid/:invalidPasscode" element={<InvalidPasscode />} />
                    <Route path="/new/:newPasscode" element={<NewWatchParty />} />
                    <Route path="/:passcode" element={<WatchPartyOnlyIfValidFormatPasscode />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </main>
              </div>
            </div>
          </div>
        </Router>
      </CacheBuster>
    </ErrorBoundary>
  )
}

export default App;
