// Lower case words and strings of numbers delimited with hyphens between 16 and 100 characters e.g. do-not-use-this-example-123
const validPasscodeFormats = /^(([a-z]+)|([0-9]+))(?:-(([a-z]+)|([0-9]+)))*$/;
const validPasscodeLengths = { min: 16, max: 100 }; // Easier than cluttering the format regexp and allows separate error explanations
function validPasscodeTest(passcode) {
  // Blank (or otherwise falsy) passcodes fail
  if (!passcode) {
    return {
      passed: false,
      failedReason: 'Please enter a passcode (or start a new watch party instead)'
    };
  }
  // Shortcut the logic for the demo passcode
  if (passcode === "demo") {
    return {
      passed: true
    }
  }
  // Test for valid format xxx-yyy-zzz style
  if (!validPasscodeFormats.test(passcode)) {
    return {
      passed: false,
      failedReason: 'Please enter a valid passcode format (lower case words and strings of numbers delimited with hyphens between 16 and 100 characters e.g. do-not-use-this-example-123)'
    };
  }
  // Test for too short
  if (passcode.length < validPasscodeLengths.min) {
    return {
      passed: false,
      failedReason: `Please enter a long enough passcode (between ${validPasscodeLengths.min} and ${validPasscodeLengths.max} characters)`
    };
  }
  // Test for too long
  if (passcode.length > validPasscodeLengths.max) {
    return {
      passed: false,
      failedReason: `Please enter a shorter passcode (between ${validPasscodeLengths.min} and ${validPasscodeLengths.max} characters)`
    };
  }
  // Passed
  return { passed: true };
}

export default validPasscodeTest;
