// See https://dev.to/woile/simplest-react-hook-component-for-pwa-install-button-2die
import React, { useEffect, useState } from "react";

const PWAInstallPrompt = () => {
  const [ supportsPWA, setSupportsPWA ] = useState(false);
  const [ promptInstall, setPromptInstall ] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("Showing prompt to install to home screen");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <button
      className="button"
      id="setup_button"
      aria-label="Install App"
      title="Install App"
      onClick={onClick}
    >
      Install App
    </button>
  );
};

export default PWAInstallPrompt;