import { useState } from 'react';

function RevealText(props) {
  const { hiddenText, shownText = "(click to show text)" } = props;
  const [ hiddenMessageIsShowing, setHiddenMessageIsShowing ] = useState(false);
  function toggleMessage() {
    setHiddenMessageIsShowing(!hiddenMessageIsShowing);
  }
  return <span onClick={toggleMessage} className="clickToReveal">{hiddenMessageIsShowing ? hiddenText : shownText}</span>
}

export default RevealText;
