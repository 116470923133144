// Lower case words and strings of numbers delimited with hyphens between 16 and 100 characters e.g. do-not-use-this-example-123
const validVideoUrlProtocols = [ 'https:', 'http:' ];
const videoVideoUrlWarningTests = [
  // YouTube Direct Links
  (parsedUrl) => {
    if (parsedUrl.hostname?.endsWith('youtube.com') || parsedUrl.hostname?.endsWith('youtu.be')) {
      return "Viewers on Quest devices will be automatically directed to the t-ne.x0.to (Jinnai) link resolver for this YouTube video, which states: This service may be terminated without notice; The author is not responsible for any damage caused by using this service; For commercial use or use beyond personal use, please contact T-NEX (https://twitter.com/ckptkb)";
    }
  },
  // Vimeo Links (Non Pro)
  (parsedUrl) => {
    // Excluding 'pro' redirects as per https://help.vimeo.com/hc/en-us/articles/224823567-Third-party-player-links since VRChat will play those on Quest
    if (parsedUrl.hostname?.endsWith('vimeo.com') && !parsedUrl.pathname?.startsWith('/progressive_redirect/')) {
      return "Viewers on Quest devices will be automatically directed to the t-ne.x0.to (Jinnai) link resolver for this Vimeo video, which states: This service may be terminated without notice; The author is not responsible for any damage caused by using this service; For commercial use or use beyond personal use, please contact T-NEX (https://twitter.com/ckptkb)";
    }
  },
  // HTTP non-secure videos
  (parsedUrl) => {
    if (parsedUrl.protocol === "http:") {
      return "Viewers on Quest devices can't watch unsecure videos ('http', rather than 'https') - this will work only for PC players 😟";
    }
  },
  // .m3u8 advice
  (parsedUrl) => {
    if (parsedUrl.pathname.endsWith('.m3u8')) {
      return "Viewers on Quest devices can only watch .m3u8 streams if the in-world video player is pointing to https://VRShare.app/watch.m3u8 (i.e. with .m3u8 added to the usual URL) - and of course the in-world player should be set to stream mode, if it has that option 👍";
    }
  }
];
function validVideoUrlTest(videoUrl) {
  if (!videoUrl) {
    return {
      passed: false,
      failedReason: 'Please enter a URL'
    };
  }
  let parsedVideoUrl;
  try {
    parsedVideoUrl = new URL(videoUrl);
  } catch (error) {
    parsedVideoUrl = null;
  }
  if (!parsedVideoUrl) {
    return {
      passed: false,
      failedReason: `Please enter a valid URL (${videoUrl} is not a well-formed URL)`
    };
  }
  if (!validVideoUrlProtocols.includes(parsedVideoUrl.protocol)) {
    return {
      passed: false,
      failedReason: `Only URLs using a valid protocol are allowed (${validVideoUrlProtocols.join(', ')}) - this URL uses ${parsedVideoUrl.protocol}`
    };
  }
  // DoS protection - no infinite loops! Replicated server-side, too
  if (parsedVideoUrl.hostname === "vrshare.app"
  || parsedVideoUrl.hostname === "www.vrshare.app"
  || parsedVideoUrl.hostname === "vrshare-app.web.app"
  || parsedVideoUrl.hostname === "vrshare-app.firebaseapp.com") {
    return {
      passed: false,
      failedReason: "Redirecting back to VRShare.app risks infinite loops, so this URL is not permitted - Got a tricky use case? Chat to us on Discord!"
    };
  }
  // No IP addresses, only hosts that expose a name via DNS are permitted to prevent DoS attack vector (IPv6 are enclosed in [])
  const ipv4Regex = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;
  if ((parsedVideoUrl.hostname.startsWith("[") && parsedVideoUrl.hostname.endsWith("]")) || ipv4Regex.test(parsedVideoUrl.hostname)) {
    return {
      passed: false,
      failedReason: "We do not currently support redirections to IP addresses for security reasons - Got a tricky use case? Chat to us on Discord!"
    };
  }
  const videoUrlWarnings = videoVideoUrlWarningTests.map(test => test(parsedVideoUrl)).filter(result => !!result).join('; ');
  if (videoUrlWarnings) {
    return {
      passed: true,
      warnings: videoUrlWarnings
    };
  }
  return { passed: true };
}

export default validVideoUrlTest;
