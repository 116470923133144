import { useState, useEffect } from "react";
import "./App.css";
import { usePromiseEffect } from "./usePromiseEffect";
import { WaitingToRender } from "./WaitingToRedirect";
import WatchParty from "./WatchParty";
import { watchPartyAlreadyExists } from './watchPartyCurrentlyPlaying';

function LoadingWatchParty(props) {
  const { passcode } = props;
  const [ watchPartyRender, setWatchPartyRender ] = useState({ ready: false, error: undefined, swallowError: undefined });
  const watchPartyData = usePromiseEffect(async () => {
    return watchPartyAlreadyExists(passcode);
  }, []);
  useEffect(() => {
    const ready = watchPartyData.status === 'fulfilled' || watchPartyData.status === 'rejected';
    if (!ready) return;
    if (watchPartyData.status === 'fulfilled' && !watchPartyData.value) {
      setWatchPartyRender({
        ready: true,
        error: new Error("❌ Watch party doesn't exist! 😢 Please use your back button and try again"),
        swallowError: true
      });
    } else if (watchPartyData.status === 'rejected') {
      setWatchPartyRender({
        ready: true,
        error: watchPartyData.error,
        swallowError: false
      });
    } else {
      setWatchPartyRender({
        ready: true,
        error: undefined,
        swallowError: undefined
      });  
    }
  }, [ watchPartyData ])
  return (
    <header className="App-header">
      <WaitingToRender state={watchPartyRender} element={<WatchParty />}/>
    </header>
  );
}

export default LoadingWatchParty;
