import { analytics, database, functions, rateLimitDatabaseReads, rateLimitDatabaseWrites, rateLimitFunctionCalls } from './firebase';
import { ref, update, get, onValue, serverTimestamp, push } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';
import { logEvent, setUserProperties } from 'firebase/analytics';
import { encode as firebaseKeyEncode } from './firebaseEncode.js'

export async function watchPartyAlreadyExists(passcode) {
  return rateLimitDatabaseReads()
    .then(() => get(ref(database, `/activewatchparties/${passcode}`)))
    .then((snapshot) => snapshot.exists());
}

export async function setWatchPartyCurrentlyPlaying(videoUrl, userId, passcode) {
  let domain = 'unknown';
  try {
    const parsedUrl = new URL(videoUrl);
    domain = parsedUrl.hostname.split('.').slice(-2).join('.');
  } catch (error) {}
  return rateLimitDatabaseWrites()
    .then(() => get(ref(database, `/killswitch`)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (Object.keys(data).includes('all')) {
          return Promise.reject(new Error(data.all || "This service is temporarily down"));
        }
        if (Object.keys(data).includes(userId)) {
          return Promise.reject(new Error(data[userId] || "This service is temporarily down"));
        }
        if (Object.keys(data).includes(passcode)) {
          return Promise.reject(new Error(data[passcode] || "This service is temporarily down"));
        }
        const videoUrlKey = firebaseKeyEncode(videoUrl);
        if (Object.keys(data).includes(videoUrlKey)) {
          return Promise.reject(new Error(data[videoUrlKey] || "This service is temporarily down"));
        }
      }
    })
    .then(() => update(ref(database, `/activewatchparties/${passcode}`), {
      currentlyPlaying: videoUrl,
      owner: userId,
      lastUpdate: serverTimestamp()
    }))
    .then(() => push(ref(database, `/analytics`), {
      event: 'updated',
      passcode,
      videoUrl,
      domain,
      userId,
      lastUpdate: serverTimestamp()
    }));
    /*.then(() => {
      alert(`set to ${videoUrl}`);
    });*/
}

/*export async function getWatchPartyCurrentlyPlaying(passcode) {
  return rateLimitDatabaseReads()
    .then(() => get(ref(database, `/activewatchparties/${passcode}`)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const currentlyPlaying = snapshot.val().currentlyPlaying;
        //alert(`got currently playing as ${currentlyPlaying}`);
        return Promise.resolve(currentlyPlaying);
      }
      logEvent(analytics, "exception", {
        description: `Could not find watch party in database: ${passcode}`,
        fatal: false
      });
      return Promise.reject(new Error('Could not find in database'));
    });
}*/

export async function observeWatchPartyCurrentlyPlaying(passcode, setCurrentlyPlayingCallback) {
  // No need to rate limit observers
  return onValue(ref(database, `/activewatchparties/${passcode}/currentlyPlaying`), (snapshot) => {
    const data = snapshot.val();
    //alert(`onValue change currentlyPlaying to ${data}`);
    setCurrentlyPlayingCallback(data);
  });
}

/*export async function observeWatchPartyForHashedIp(hashedIp, setCurrentWatchPartyForHashedIpCallback) {
  // No need to rate limit observers
  return onValue(ref(database, `/activeviewers/${hashedIp}/currentWatchParty`), (snapshot) => {
    const data = snapshot.val();
    //alert(`onValue change currentWatchParty for hashedIp ${hashedIp} to ${data}`);
    setCurrentWatchPartyForHashedIpCallback(data);
  });
}*/

export async function observeNumViewersInWatchParty(passcode, setNumViewersCallback) {
  // No need to rate limit observers
  return onValue(ref(database, `/activewatchparties/${passcode}/viewers`), (snapshot) => {
    const numViewers = snapshot.size;
    //alert(`onValue change to number of viewers to ${numViewers}`);
    setNumViewersCallback(numViewers);
  });
}

export async function associateToWatchParty(passcode) {
  const associateExternalIpToWatchParty = httpsCallable(functions, 'associateExternalIpToWatchParty');
  return rateLimitFunctionCalls()
    .then(() => associateExternalIpToWatchParty({ passcode }))
    .then((result) => {
      logEvent(analytics, "joined_watch_party");
      setUserProperties(analytics, { in_watch_party: true });
      return result.data;
    });
}

export async function disassociateFromWatchParty(passcode) {
  const disassociateExternalIpFromWatchParty = httpsCallable(functions, 'disassociateExternalIpFromWatchParty');
  return rateLimitFunctionCalls()
    .then(() => disassociateExternalIpFromWatchParty({ passcode }))
    .then(() => {
      logEvent(analytics, "left_watch_party");
      setUserProperties(analytics, { in_watch_party: false });
    });
}

/*export async function getHashedIp() {
  const getHashedIpFunction = httpsCallable(functions, 'getHashedIp');
  return rateLimitFunctionCalls()
    .then(() => getHashedIpFunction())
    .then((result) => result.data);
}*/
