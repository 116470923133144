import { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useSearchParams
} from "react-router-dom";
import "./App.css";
import ErrorMessage from './ErrorMessage';
import { RequiresSignIn } from './SignInOrSignOut';
import { usePromiseEffect } from './usePromiseEffect';
import validVideoUrlTest from './validVideoUrlTest';
import {
  setWatchPartyCurrentlyPlaying,
  observeWatchPartyCurrentlyPlaying,
  associateToWatchParty,
  disassociateFromWatchParty,
  observeNumViewersInWatchParty
} from './watchPartyCurrentlyPlaying';
import { analytics, auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { logEvent } from 'firebase/analytics';
import VideoCard from './VideoCard';
import RevealText from './RevealText';

const defaultVideoUrl = "https://youtu.be/Pcl89zHT83Q";

/*function FirstPlayerAdvice() {
  return (
    <>
      <p>Navigate the in-world video player to <b>vrshare.app</b> to start watching (or refresh/resync the player)</p>
      <p>Share the passcode so others can join, otherwise they'll only see a default video!</p>
      <p>Maybe write it in-world if there are pens 🖊</p>
    </>
  );
}

function NextPlayerAdvice() {
  return (
    <>
      <p>Resync the in-world video player to join in watching.</p>
      <p>Or rejoin the world instance, to avoid disrupting other players and sync automatically 👍</p>
    </>
  );
}*/

function WatchParty() {
  const { passcode } = useParams();

  const [ ip, setIp ] = useState(<>(loading...)</>);
  const [ numViewers, setNumViewers ] = useState(<>Loading...</>);
  const ipAndInitialNumViewersData = usePromiseEffect(async () => {
    return associateToWatchParty(passcode);
  }, []);
  useEffect(() => {
    const ready = ipAndInitialNumViewersData.status === 'fulfilled' || ipAndInitialNumViewersData.status === 'rejected';
    if (!ready) return;
    if (ipAndInitialNumViewersData.status === 'rejected') {
      setIp(<ErrorMessage error={ipAndInitialNumViewersData.error} alertOnly={true} extraInfo={ipAndInitialNumViewersData.value} />);
      return;
    }
    if (!ipAndInitialNumViewersData.value.ip) {
      setIp(<ErrorMessage error={new Error('No ip returned in data')} alertOnly={true} extraInfo={ipAndInitialNumViewersData.value} />);
      return;
    }
    setIp(<>{ipAndInitialNumViewersData.value.ip}</>);
    setNumViewers(<>{ipAndInitialNumViewersData.value.numViewers || '?'}</>)
  }, [ ipAndInitialNumViewersData ]);
  const numViewersObserver = usePromiseEffect(async () => {
    return observeNumViewersInWatchParty(passcode, setNumViewers);
  }, []);
  useEffect(() => {
    const numViewersObserverReturned = numViewersObserver.status === 'fulfilled' || numViewersObserver.status === 'rejected';
    if (!numViewersObserverReturned) return;
    const numViewersObserverIsReady = numViewersObserver.status === 'fulfilled';
    if (numViewersObserverIsReady) {
      //alert('Unsubscriber registered');
      return function cleanup() {
        // The observer promise returns it's unsubscribe function, so this is how we unsubscribe
        //alert('Unsubscriber unregistered');
        numViewersObserver.value();
      }
    }
  }, [ numViewersObserver ]);

  const [ videoUrl, setVideoUrl ] = useState('');
  const watchPartyObserver = usePromiseEffect(async () => {
    return observeWatchPartyCurrentlyPlaying(passcode, setVideoUrl);
  }, []);
  useEffect(() => {
    const watchPartyObserverReturned = watchPartyObserver.status === 'fulfilled' || watchPartyObserver.status === 'rejected';
    if (!watchPartyObserverReturned) return;
    const watchPartyObserverIsReady = watchPartyObserver.status === 'fulfilled';
    if (watchPartyObserverIsReady) {
      //alert('Unsubscriber registered');
      return function cleanup() {
        // The observer promise returns it's unsubscribe function, so this is how we unsubscribe
        //alert('Unsubscriber unregistered');
        watchPartyObserver.value();
      }
    }
  }, [ watchPartyObserver ]);

  const [ desiredVideoUrl, setDesiredVideoUrl ] = useState('');
  const [ desiredVideoUrlReadyForDatabase, setDesiredVideoUrlReadyForDatabase ] = useState('');
  const [ dropboxThumbnail, setDropboxThumbnail ] = useState();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ user ] = useAuthState(auth);
  const setVideoUrlInDatabaseData = usePromiseEffect(async () => {
    if (desiredVideoUrlReadyForDatabase === '') return;
    //alert(`Would change to ${desiredVideoUrlReadyForDatabase}`);
    return setWatchPartyCurrentlyPlaying(desiredVideoUrlReadyForDatabase, user?.uid || 'anonymous', passcode)
      .then(() => {
        if (desiredVideoUrlReadyForDatabase === defaultVideoUrl) {
          logEvent(analytics, "watch_party_video_set_to_default");
        } else {
          let domain = 'unknown';
          try {
            const parsedUrl = new URL(desiredVideoUrlReadyForDatabase);
            domain = parsedUrl.hostname.split('.').slice(-2).join('.');
          } catch (error) {
            logEvent(analytics, "exception", {
              description: `Invalid URL being sent for watch party: ${desiredVideoUrlReadyForDatabase}`,
              fatal: false
            });
          }
          logEvent(analytics, "watch_party_video_changed", {
            domain
          });
        }
      })
      .catch((error) => {
        logEvent(analytics, "exception", {
          description: `Unexpected error loading URL for watch party: ${error.message}`,
          fatal: false
        });
        console.log(`Sorry, there was an error! Message: ${error.message}`);
        alert(`Sorry, there was an error! Message: ${error.message}`);
      });
  }, [ desiredVideoUrlReadyForDatabase ]);
  useEffect(() => {
    const ready = setVideoUrlInDatabaseData.status === 'fulfilled' || setVideoUrlInDatabaseData.status === 'rejected';
    if (!ready) return;
    // Use the desired URL passed in from search params, if present
    const searchParamsUrl = searchParams.get('url');
    const searchParamsHasDesiredVideoUrl = !!searchParamsUrl; // Ignore falsy e.g. empty and undefined etc.
    if (searchParamsHasDesiredVideoUrl) {
      setDesiredVideoUrl(searchParamsUrl);
      searchParams.delete('url');
      setSearchParams(searchParams);
    } else {
      setDesiredVideoUrl('');
    }
  }, [ setVideoUrlInDatabaseData ]); // eslint-disable-line react-hooks/exhaustive-deps

  function desiredVideoUrlChanged(event) {
    setDesiredVideoUrl(event.target.value.trim());
  }
  function useDesiredVideoUrl() {
    const testResult = validVideoUrlTest(desiredVideoUrl);
    if (!testResult.passed) {
      alert(testResult.failedReason);
      return;
    }
    if (testResult.warnings) {
      alert(testResult.warnings);
    }
    setDesiredVideoUrlReadyForDatabase(desiredVideoUrl);
  }
  function removeVideoUrl() {
    setDesiredVideoUrlReadyForDatabase(defaultVideoUrl);
  }
  function handleEnter(event) {
    if (event.key !== 'Enter') return;
    const testResult = validVideoUrlTest(desiredVideoUrl);
    if (!testResult.passed) {
      alert(testResult.failedReason);
      return;
    }
    if (testResult.warnings) {
      alert(testResult.warnings);
    }
    setDesiredVideoUrlReadyForDatabase(desiredVideoUrl);
  }

  function leaveWatchParty(_event) {
    // An async function called syncrhonously, but we'll let the result get lost - we don't process it
    disassociateFromWatchParty(passcode);
  }

  function copyPasscode(_event) {
    navigator.clipboard.writeText(passcode)
      .then(() => alert("Passcode copied to clipboard"));
  }

  function getExtensionForVideoUrl(url) {
    try {
      const parsedUrl = new URL(url);
      if (parsedUrl.pathname.endsWith('.m3u8')) {
        return '.m3u8'
      } else {
        return '';
      }
    } catch (_error) {
      return '';
    }
  }

  function copyMediaUrl(_event) {
    navigator.clipboard.writeText(`https://vrshare.app/watch/${passcode}${getExtensionForVideoUrl(videoUrl)}`)
      .then(() => alert("Watch party URL for in-world video players copied to clipboard"));
  }

  function copyGenericUrl(_event) {
    navigator.clipboard.writeText(`https://vrshare.app/watch${getExtensionForVideoUrl(videoUrl)}`)
      .then(() => alert("General URL for in-world video players copied to clipboard"));
  }

  const [ dropboxScriptLoaded, setDropboxScriptLoaded ] = useState(false);
  function DropboxAddButton() {
    return <button id="addDropboxVideoButton" className="button" onClick={chooseFromDropbox}>Add from Dropbox</button>
  }
  function loadDropboxScript(callback) {
    const existingScript = document.getElementById('dropboxjs');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
      script.id = 'dropboxjs';
      script.setAttribute("data-app-key", "9u9gtd3p0rvz608");
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  }
  useEffect(() => {
    loadDropboxScript(() => {
      setDropboxScriptLoaded(true);
    });
  }, []);

  function setUrlFromDropbox(desiredVideoUrl, thumbnailUrl) {
    const testResult = validVideoUrlTest(desiredVideoUrl);
    if (!testResult.passed) {
      alert(testResult.failedReason);
      return;
    }
    if (testResult.warnings) {
      alert(testResult.warnings);
    }
    setDesiredVideoUrlReadyForDatabase(desiredVideoUrl);
    setDropboxThumbnail(thumbnailUrl);
  }

  function chooseFromDropbox(_event) {
    try {
      // eslint-disable-next-line no-undef
      Dropbox.choose({
        success: function(files) { setUrlFromDropbox(files[0].link, files[0].thumbnailLink); },
        linkType: "direct",
        extensions: ['.mp4','.webm','.m3u8']
      });
    } catch (error) {
      console.error(error);
      alert("Sorry, could not load the Dropbox API");
    }
  }

  const signInPromptText = videoUrl !== defaultVideoUrl ? "Sign in to change or remove this video URL" : "Sign in to add a video - it's free!";
  const submitLabel = videoUrl !== defaultVideoUrl ? 'Change' : 'Add';
  const removeLabel = videoUrl !== defaultVideoUrl ? 'Remove Current' : undefined;
  // const advice = videoUrl !== defaultVideoUrl && numViewers > 1 ? NextPlayerAdvice() : FirstPlayerAdvice();
  return (
    <div>
      <header className="App-header">
        <h1 className="actionableText" onClick={copyPasscode}>{passcode}<img className="inlineIcon" src="copy.svg" alt="copy" /></h1>
        <div>
          <p className="keepWithNextLine">Media players pointing to</p>
          <p className="keepWithPreviousLine"><b className='actionableText' onClick={copyMediaUrl}>https://vrshare.app/watch/{passcode}{getExtensionForVideoUrl(videoUrl)}<img className="inlineIcon" src="copy.svg" alt="copy" /></b></p>
          <p className="keepWithPreviousLine keepWithNextLine">and media players from <RevealText shownText="REVEAL IP" hiddenText={ip} /> pointing to</p>
          <p className="keepWithPreviousLine"><b className='actionableText' onClick={copyGenericUrl}>https://vrshare.app/watch{getExtensionForVideoUrl(videoUrl)}<img className="inlineIcon" src="copy.svg" alt="copy" /></b></p>
          <p className="keepWithPreviousLine keepWithNextLine">will see:</p>
          {videoUrl === defaultVideoUrl
            ? <VideoCard source="Waiting Screen" title="Default Video" url={videoUrl} />
            : passcode === "demo"
              ? <VideoCard source="Demo Video" title="Default Video" url={videoUrl} />
              : <VideoCard thumbnail={dropboxThumbnail} url={videoUrl} />
          }
          <p className="keepWithPreviousLine">after joining or re-syncing.</p>
        </div>
        <p>{numViewers > 1 ? `You're currently watching with ${numViewers - 1} other${numViewers > 2 ? 's' : ''}` : "You're the only one here"}</p>
        {
          passcode !== "demo"
            ?
              <RequiresSignIn signInPromptText={signInPromptText}>
                {removeLabel ? <button className="button" onClick={removeVideoUrl}>{removeLabel}</button> : <></>}
                <div>
                  <input type="text" placeholder="Enter new video URL" onChange={desiredVideoUrlChanged} onKeyUp={handleEnter} value={desiredVideoUrl}></input>
                  <div className='button-pulse'>
                    <div className="button__wrapper">
                      <button id="addNewVideoButton" className={desiredVideoUrl === '' ? 'button' : 'button pulsing'} onClick={useDesiredVideoUrl}>{submitLabel}</button>
                    </div>
                  </div>
                </div>
                {dropboxScriptLoaded ? <DropboxAddButton /> : <></>}
              </RequiresSignIn>
            :
              <></>
        }
        <br />
        <Link className="button" to={`/${passcode ? `?oldPasscode=${passcode}` : ''}`} onClick={leaveWatchParty}>Leave watch party</Link>
      </header>
    </div>
  );
}

export default WatchParty;
