import manifest from './vrshare-extension/manifest.json';

const { version } = manifest;

const chromeRefreshIcon = <svg className="inlineIconSmall" style={{ "marginBottom": 0, height: "3vmin", width: "3vmin" }} viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="#5F6368"><path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>

function copyExtensionsShortcut() {
  navigator.clipboard.writeText("chrome://extensions")
    .then(() => alert("Extensions page URL copied to clipboard - paste it into a new tab\n(Chrome doesn't allow us to link to it directly)"))
    .then(() => window.open());
}

function ExtensionModal(props) {
  const { closeCallback } = props;
  const closeCallbackIsAvailable = typeof closeCallback === 'function';
  return <>
    <h2>Get the Extension - v{version}</h2>
    <h3>Easy video sharing for VRChat PC Users:</h3>
    <br />
    <div className="modalScrollableContent">
      <h3>On PC, download the latest Chrome Extension here:</h3>
      <ul><li><a href="vrshare-extension.zip" alt="Download the extension">vrshare-extension.zip - v{version}</a></li></ul>
      <p>Then:</p>
      <ol>
        <li>Unzip it somewhere on your PC</li>
        <li>Sift through the code to reassure yourself that I won't be using your CPU cycles for mining bitcoin or similar 😁</li>
        <li>Navigate to <span className="actionableText" onClick={copyExtensionsShortcut}>chrome://extensions<img className="inlineIconSmall" src="copy.svg" alt="copy" /></span> in a new tab</li>
        <li>Enable developer mode, if you haven't already (top right)</li>
        <li>Click 'Load Unpacked' (top left)</li>
        <li>Find and select the 'vrshare-extension' folder you unzipped earlier</li>
        <li>Profit.</li>
      </ol>
      <br />
      <h3>What's a Chrome extension?</h3>
      <p>Browser extensions can execute extra functionality on a webpage. They might perform an action when you click on the extension icon in your browser, or they may add context (right-click) menu options relevant to whatever the mouse is hovering over. This one does both.</p>
      <br />
      <h3>How do you use it?</h3>
      <p>When you're on a web page with a video you want to watch in VRChat, simply right click and you can find VRShare options in the context menu that appears</p>
      <p>Depending what you right click on you can share:</p>
      <ol>
        <li>The current page</li>
        <li>Links on the page</li>
        <li>Sources of media URLs (assuming they are e.g. mp4 files or similar, and not 'blob' streams that commercial video hosts use)</li>
        <li>Selected text (in case a URL is written in plain text and not an actual link)</li>
        <li>The current frame (doesn't always work due to Cross Origin Resource Sharing restrictions)</li>
      </ol>
      <p>If you click the VRShare icon in the list of extensions instead, it will share the current page.</p>
      <br />
      <h3>Which should I choose?</h3>
      <p>If you're on a well-known video platform, like YouTube or Vimeo, viewing the video you want to share, just use the current page URL</p>
      <p>If you're on a page with video links (like the YouTube front page feed), right click on the desired video thumbnail or link, then share the link URL</p>
      <p>If you're on a page with an embedded video player, try right clicking the video itself - you might get both a video and a frame URL to try. If this doesn't work, use the video player controls to open the video in a new tab and go from there (e.g. on embedded YouTube players there is a YouTube logo that says "Watch on youtube.com" when you hover your mouse over it)</p>
      <br />
      <h3>Why isn't it on the Chrome Web Store?</h3>
      <p>The Chrome Web Store won't allow extensions that extract sources of videos from e.g. YouTube.</p>
      <p>But installing it manually is simple enough with the instructions above. And the code is plaintext and fairly small, so you can (and should!) review it to satisfy yourself we're not doing anything malicious 👍</p>
      <br />
      <h3>How do you update the extension?</h3>
      <p>Extensions installed thgrough the Chrome Web Store are automatically kept up to date. Since we can't do that, the extension reports it's version when it is used and you will see a popup if a newer one is available.</p>
      <p>If it needs updating:</p>
      <ol>
        <li>Download the latest one from the link</li>
        <li>Unzip it over the top of wheverer you previously downloaded it</li>
        <li>Navigate to <span className="actionableText" onClick={copyExtensionsShortcut}>chrome://extensions<img className="inlineIconSmall" src="copy.svg" alt="copy" /></span> in a new tab</li>
        <li>Find 'VRShare' in the list</li>
        <li>Click the update icon: {chromeRefreshIcon}</li>
      </ol>
      <p>You need to refresh any tabs you already have open to use the new version from that page.</p>
      <br />
      <h3>This seems complicated, isn't there an easier way?</h3>
      <p>You can always try the bookmarklet instead. It's less powerful and there's no context menu, but the installation is as easy as drag and drop 👍</p>
    </div>
    <div className="modalFooter">
      <h3>On PC, download the latest Chrome Extension here:</h3>
      <h3><a href="vrshare-extension.zip" alt="Download the extension">VRShare Extension Zip File - v{version}</a></h3>
      <h3>Use it on any video web page!</h3>
      {closeCallbackIsAvailable ? <p><img className="closeModalButton" src="close.svg" alt="close" onClick={closeCallback} /></p> : <></>}
    </div>
  </>
}

export default ExtensionModal;