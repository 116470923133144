import { analytics, auth } from './firebase'
import { signInWithPopup, signOut, GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { logEvent, setUserProperties } from 'firebase/analytics';

function appSignInWithGoogle() {
  return signInWithPopup(auth, new GoogleAuthProvider())
    .then((result) => {
      if (result.user) {
        logEvent(analytics, "login", {
          method: 'Google'
        });
      }
      if (getAdditionalUserInfo(result)?.isNewUser) {
        logEvent(analytics, "sign_up", {
          method: 'Google'
        });
      }
    })
    .catch((error) => {
      logEvent(analytics, "exception", {
        description: `login error: ${error.message}`,
        fatal: false
      });
    });
}

function appSignOut() {
  return signOut(auth).then(() => {
      logEvent(analytics, "logout");
    }).catch((error) => {
      logEvent(analytics, "exception", {
        description: `logout error: ${error.message}`,
        fatal: false
      });
    });
}

function SignIn(props) {
  const { signInPromptText } = props;
  return (
    <>
      <button className="button" onClick={appSignInWithGoogle}>{signInPromptText || "Sign in"}</button>
    </>
  );
}

export function RequiresSignIn(props) {
  const { children, signInPromptText } = props;
  const [ user ] = useAuthState(auth);
  if (user) {
    setUserProperties(analytics, { logged_in: true });
    if (user.email === "daninvirtualreality@gmail.com") {
      setUserProperties(analytics, { user_type: 'admin' });
    } else {
      setUserProperties(analytics, { user_type: 'user' });
    }
    return children || <SignOut displayName={user.displayName} />
  }
  setUserProperties(analytics, { logged_in: false });
  return <SignIn signInPromptText={signInPromptText} />
}

function SignOut(props) {
  const { displayName } = props;
  return (
    props.displayName
      ?
        <>
          <button id="signOut" className="button" onClick={appSignOut}>Sign Out<br />({displayName})</button>
        </>
      :
        <>
          <button id="signOut" className="button" onClick={appSignOut}>Sign Out</button>
        </>
  );
}

function SignInOrSignOut() {
  return <RequiresSignIn />
}

export default SignInOrSignOut;