import { useState } from 'react';
import {
  useSearchParams,
  useNavigate
} from "react-router-dom";
import GeneratePasscode from './GeneratePasscode';
import validPasscodeTest from './passCodeTests';

function InputPasscode(props) {
  const { updatePasscode, oldPasscode, passcode, searchParams } = props;
  const navigate = useNavigate();
  function handleEnter(event) {
    if (event.key !== 'Enter') return;
    const testResult = validPasscodeTest(passcode);
    if (!testResult.passed) {
      alert(testResult.failedReason);
      return;
    }
    searchParams.delete('oldPasscode');
    const searchString = searchParams.toString();
    const queryString = searchString ? `?${searchString}` : '';
    navigate(passcode + queryString);
  }
  return <input type="text" placeholder="Enter pass code" onInput={updatePasscode} onKeyUp={handleEnter} defaultValue={oldPasscode}></input>
}

function JoinButton(props) {
  const { passcode, oldPasscode, invalidPasscode, searchParams } = props;
  const navigate = useNavigate();
  function handleClick() {
    const testResult = validPasscodeTest(passcode);
    if (!testResult.passed) {
      alert(testResult.failedReason);
      return;
    }
    searchParams.delete('oldPasscode');
    const searchString = searchParams.toString();
    const queryString = searchString ? `?${searchString}` : '';
    navigate(passcode + queryString);
  }
  return <button className="button" onClick={handleClick}>{passcode && passcode === oldPasscode && passcode !== invalidPasscode ? 'Rejoin your previous' : 'Join a'} watch party</button>
}

function Home(props) {
  const { version } = props;
  const [ searchParams ] = useSearchParams();
  const invalidPasscode = searchParams.get('invalidPasscode');
  const oldPasscode = searchParams.get('oldPasscode') || invalidPasscode || '';
  const [ passcode, setPasscode ] = useState(oldPasscode);
  function updatePasscode(event) {
    setPasscode(event.target.value.trim());
  }

  return (
    <header className="App-header">
      <h1>VRShare.app</h1>
      <p className='versionInfo'>v {version}</p>
      <img src='icon-white.svg' className="App-logo" alt="logo" />
      <br/>
      <InputPasscode updatePasscode={updatePasscode} oldPasscode={oldPasscode} passcode={passcode} searchParams={searchParams} />
      <JoinButton passcode={passcode} oldPasscode={oldPasscode} invalidPasscode={invalidPasscode} searchParams={searchParams} />
      <p>or</p>
      <GeneratePasscode />
      <br />
    </header>
  );
}

export default Home;
