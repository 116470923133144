import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

function ErrorMessage(props) {
  const { error, alertOnly, alertMessage, swallowError, extraInfo } = props;
  if (!swallowError) {
    console.error(error, extraInfo);
  }
  logEvent(analytics, "exception", {
    description: `Error: ${error?.message}; Extra Info: ${extraInfo}; Available In Console: ${!swallowError}`,
    fatal: false
  });
  const message = error?.message || 'There was an error 😢 - See the console for more details';
  const defaultAlertMessage = `There was an error 😢 - ${error?.message || 'See the console for more details'}`;
  if (alertOnly) {
    alert(alertMessage || defaultAlertMessage);
    return '(error!)';
  }
  if (alertMessage) {
    alert(alertMessage);
  }
  return <>{message}</>
}

export default ErrorMessage;
