import {
  Navigate
} from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

export function Waiting() {
  return <h1>Loading...</h1>
}

export function WaitingError(props) {
  const { error, swallowError } = props;
  return <h1><ErrorMessage error={error} swallowError={swallowError} /></h1>
}

export function WaitingToRedirect(props) {
  const { state } = props;
  return state?.ready
    ? (state?.error ? <WaitingError error={state.error} swallowError={state.swallowError} /> : <Navigate replace to={state?.destination} />)
    : <Waiting />
}

export function WaitingToRender(props) {
  const { state, element } = props;
  return state?.ready
    ? (state?.error ? <WaitingError error={state.error} swallowError={state.swallowError} /> : element)
    : <Waiting />
}
