import {
  Link,
  useParams
} from "react-router-dom";
import validPasscodeTest from './passCodeTests';
import "./App.css";

function InvalidPasscode() {
  const { invalidPasscode } = useParams();
  const invalidReason = validPasscodeTest(invalidPasscode).failedReason;
  return (
    <header className="App-header">
      <h1>Invalid Passcode</h1>
      <h2>{invalidPasscode}</h2>
      <p>{invalidReason}</p>
      <br />
      <Link className="button" to={`/?invalidPasscode=${invalidPasscode}`}>Home</Link>
    </header>
  );
}

export default InvalidPasscode;
