import { useEffect } from "react";
import {
  useSearchParams
} from "react-router-dom";
import { compare, validate } from 'compare-versions';
import { version as currentBookmarkletVersion } from './vrshare-bookmarklet-src';
import extensionManifest from './vrshare-extension/manifest.json';

const { version: currentExtensionVersion } = extensionManifest;

function isThereNewVersion(userVersion, currentVersion) {
  return compare(currentVersion, userVersion, '>');
};

function QueryCleanser() {
  const [ searchParams, setSearchParams ] = useSearchParams();
  function cleanseSearchParameters() {
    
    // Query whitelist, aside form share data (could be more scalable, but it works)
    const oldPasscode = searchParams.get('oldPasscode');
    const allowedQueryOldPasscode = oldPasscode ? { oldPasscode } : {};
    
    const invalidPasscode = searchParams.get('invalidPasscode');
    const allowedInvalidPasscode = invalidPasscode ? { invalidPasscode } : {};

    const userBookmarkletVersion = searchParams.get('bookmarklet');
    if (userBookmarkletVersion && validate(userBookmarkletVersion) && isThereNewVersion(userBookmarkletVersion, currentBookmarkletVersion)) {
      alert(`FYI there's a new version of the bookmarklet (${currentBookmarkletVersion}) available - install it from the menu when convenient 👍`);
    } // else just cleanse the parameter

    const userExtensionVersion = searchParams.get('extension');
    if (userExtensionVersion && validate(userExtensionVersion) && isThereNewVersion(userExtensionVersion, currentExtensionVersion)) {
      alert(`FYI there's a new version of the extension (${currentExtensionVersion}) available - install it from the menu when convenient 👍`);
    } // else just cleanse the parameter
    
    const allowedQueryElements = { ...allowedQueryOldPasscode, ...allowedInvalidPasscode };
    
    // shortcut without change if there are no elements to cleanse (i.e. the allowed ones above plus 'url' which we don't process if it's alone)
    const extraQueryElements = Array.from(searchParams.keys()).filter((element) => element !== 'url' && !Object.keys(allowedQueryElements).includes(element));
    const hasExtraQueryElements = extraQueryElements.length > 0;
    if (!hasExtraQueryElements) return;
    console.log("Extra search parameters received:", extraQueryElements);
    
    // Now process share target parameters, if present
    const shareTargetQueryValues = [];
    const url = searchParams.get('url');
    const text = searchParams.get('text');
    const title = searchParams.get('title');
    
    // Priority order defined here as we will only take the first URL - default value of 'empty' ensures whitespace in join() is predictable
    if (url) shareTargetQueryValues.push(url);
    if (text) shareTargetQueryValues.push(text);
    if (title) shareTargetQueryValues.push(title);
    console.log("Share target parameters:", shareTargetQueryValues);
    const shareTargetQueryValuesJoined = shareTargetQueryValues.join(' ');
    
    // Shortcut if we have no share data
    if (!shareTargetQueryValuesJoined) {
      console.log("No processable search parameters in the extras");
      setSearchParams(allowedQueryElements);
      return;
    }
    
    // We expect the URL to be in there somewhere, delimited by either ^, $ or ' ' (but let's not use regex)
    const shareTargetQueryStrings = shareTargetQueryValuesJoined.split(' ');
    
    // We expect the URL to start with an acceptable protocol
    const potentialUrls = shareTargetQueryStrings.filter((element) => element?.startsWith('http://') || element?.startsWith('https://'));
    
    // Condense shared info down to the URL, if we received present
    if (potentialUrls.length > 0) {
      setSearchParams({
        url: potentialUrls[0],
        ...allowedQueryElements
      });
      return;
    }
    
    // We got data, but cannot find a URL
    alert(`Sorry, "${shareTargetQueryStrings.join(' ')}" doesn't seem to contain a valid URL 🤷‍♂️`);
    setSearchParams(allowedQueryElements);
  }
  useEffect(() => {
    cleanseSearchParameters();
  }, [ searchParams ]) // eslint-disable-line react-hooks/exhaustive-deps
  return <></>
}

export default QueryCleanser;