import { initializeApp } from 'firebase/app';

import { getAuth, connectAuthEmulator} from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
//import "firebase/pubsub";

const firebase = initializeApp({
  apiKey: "AIzaSyCSeUaQEXTiM16-yYXgBaF35dQNXFeObHA",
  authDomain: "vrshare-app.firebaseapp.com",
  databaseURL: "https://vrshare-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vrshare-app",
  storageBucket: "vrshare-app.appspot.com",
  messagingSenderId: "353081749843",
  appId: "1:353081749843:web:5b817f6e5ae84cdf52f1a6",
  measurementId: "G-D199KCCKLH"
});

export const auth = getAuth(firebase);
export const database = getDatabase(firebase);
export const functions = getFunctions(firebase);
export const storage = getStorage(firebase);
export const analytics = getAnalytics(firebase);
export const performance = getPerformance(firebase);
export var emulating = false;

if (window.location.hostname === "localhost") {
  emulating = true;
  connectAuthEmulator(auth, "http://localhost:9099");
  connectDatabaseEmulator(database, "localhost", 9000);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
  if (emulating) {
    setUserProperties(analytics, { emulating: true });
  } else {
    setUserProperties(analytics, { emulating: false });
  }
  // hosting emulator is at  5000
  // pubsub emulator is at 8085
}

async function rateLimitWait(delay) {
  const delayToUse = Math.max(0, delay);
  if (delayToUse > 0) {
    console.warn(`Rate limited - waiting for ${delayToUse / 1000} seconds...`);
  }
  return new Promise((resolve) => setTimeout(resolve, delayToUse));
}

const delayBetweenDatabaseWrites = 5000;
let lastDatabaseWrite = 0;
export function rateLimitDatabaseWrites() {
  const now = Date.now();
  const canWriteAt = lastDatabaseWrite + delayBetweenDatabaseWrites;
  const delay = canWriteAt - now;
  return rateLimitWait(delay).then(() => { lastDatabaseWrite = Date.now(); });
}

const delayBetweenDatabaseReads = 5000;
let lastDatabaseRead = 0;
export function rateLimitDatabaseReads() {
  const now = Date.now();
  const canWriteAt = lastDatabaseRead + delayBetweenDatabaseReads;
  const delay = canWriteAt - now;
  return rateLimitWait(delay).then(() => { lastDatabaseRead = Date.now(); });
}

const delayBetweenFunctionCalls = 5000;
let lastFunctionCall = 0;
export function rateLimitFunctionCalls() {
  const now = Date.now();
  const canWriteAt = lastFunctionCall + delayBetweenFunctionCalls;
  const delay = canWriteAt - now;
  return rateLimitWait(delay).then(() => { lastFunctionCall = Date.now(); });
}

export default firebase;
